/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getClients = /* GraphQL */ `query GetClients($companycode: String!) {
  getClients(companycode: $companycode) {
    companycode
    name
    tenants {
      items {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientsQueryVariables,
  APITypes.GetClientsQuery
>;
export const listClients = /* GraphQL */ `query ListClients(
  $companycode: String
  $filter: ModelClientsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listClients(
    companycode: $companycode
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companycode
      name
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const getTenants = /* GraphQL */ `query GetTenants($id: String!) {
  getTenants(id: $id) {
    id
    subdomain
    usesHubs
    name
    url
    uniqueid
    createdby
    logo
    loginsideimage
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    hubs {
      items {
        id
        chubno
        tenantid
        description
        logo
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        tenantsHubsId
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    linkedclient {
      companycode
      name
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    clientsTenantsCompanycode
    tenantsLinkedclientCompanycode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTenantsQueryVariables,
  APITypes.GetTenantsQuery
>;
export const listTenants = /* GraphQL */ `query ListTenants(
  $id: String
  $filter: ModelTenantsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTenants(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenantsQueryVariables,
  APITypes.ListTenantsQuery
>;
export const getUsers = /* GraphQL */ `query GetUsers($id: String!) {
  getUsers(id: $id) {
    id
    username
    firstname
    lastname
    tenants {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $id: String
  $filter: ModelUsersFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      username
      firstname
      lastname
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getHubs = /* GraphQL */ `query GetHubs($id: ID!) {
  getHubs(id: $id) {
    id
    chubno
    tenantid
    description
    logo
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    tenant {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    createdAt
    updatedAt
    tenantsHubsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHubsQueryVariables, APITypes.GetHubsQuery>;
export const listHubs = /* GraphQL */ `query ListHubs(
  $id: ID
  $filter: ModelHubsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHubs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      chubno
      tenantid
      description
      logo
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      tenant {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      createdAt
      updatedAt
      tenantsHubsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListHubsQueryVariables, APITypes.ListHubsQuery>;
export const getTenantsUsers = /* GraphQL */ `query GetTenantsUsers($id: ID!) {
  getTenantsUsers(id: $id) {
    id
    tenantsId
    usersId
    tenants {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    users {
      id
      username
      firstname
      lastname
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTenantsUsersQueryVariables,
  APITypes.GetTenantsUsersQuery
>;
export const listTenantsUsers = /* GraphQL */ `query ListTenantsUsers(
  $filter: ModelTenantsUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  listTenantsUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantsId
      usersId
      tenants {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenantsUsersQueryVariables,
  APITypes.ListTenantsUsersQuery
>;
export const tenantBySubdomain = /* GraphQL */ `query TenantBySubdomain(
  $subdomain: String!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTenantsFilterInput
  $limit: Int
  $nextToken: String
) {
  tenantBySubdomain(
    subdomain: $subdomain
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenantBySubdomainQueryVariables,
  APITypes.TenantBySubdomainQuery
>;
export const userByUsername = /* GraphQL */ `query UserByUsername(
  $username: String!
  $id: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  userByUsername(
    username: $username
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      firstname
      lastname
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByUsernameQueryVariables,
  APITypes.UserByUsernameQuery
>;
export const hubByChubno = /* GraphQL */ `query HubByChubno(
  $chubno: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHubsFilterInput
  $limit: Int
  $nextToken: String
) {
  hubByChubno(
    chubno: $chubno
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chubno
      tenantid
      description
      logo
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      tenant {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      createdAt
      updatedAt
      tenantsHubsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HubByChubnoQueryVariables,
  APITypes.HubByChubnoQuery
>;
export const hubByTenantId = /* GraphQL */ `query HubByTenantId(
  $tenantid: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHubsFilterInput
  $limit: Int
  $nextToken: String
) {
  hubByTenantId(
    tenantid: $tenantid
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chubno
      tenantid
      description
      logo
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      tenant {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      createdAt
      updatedAt
      tenantsHubsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HubByTenantIdQueryVariables,
  APITypes.HubByTenantIdQuery
>;
export const tenantsUsersByTenantsId = /* GraphQL */ `query TenantsUsersByTenantsId(
  $tenantsId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTenantsUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  tenantsUsersByTenantsId(
    tenantsId: $tenantsId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantsId
      usersId
      tenants {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenantsUsersByTenantsIdQueryVariables,
  APITypes.TenantsUsersByTenantsIdQuery
>;
export const tenantsUsersByUsersId = /* GraphQL */ `query TenantsUsersByUsersId(
  $usersId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTenantsUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  tenantsUsersByUsersId(
    usersId: $usersId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantsId
      usersId
      tenants {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenantsUsersByUsersIdQueryVariables,
  APITypes.TenantsUsersByUsersIdQuery
>;
