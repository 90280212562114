import React from "react";
import StoreItems, { Category, WebInventoryItem } from "../components/StoreItems";
import "../styles.css";

import { Box, Hidden, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import { ScheduleAddress } from "../components/StoreItems";
import StoreSideBar from "../components/StoreSideBar";
import { useIsMobile } from "helpers/CustomHooks";
import Container from "@mui/material/Container";
import NavBreadcrumbs from "helpers/NavBreadcrumbs";
//const MainStore: React.FC<Props> = ({ items, onCancel }) => {

export const TopMenuWrapper = styled.div`
    position: sticky;
    top: 0;
    display: none;
`;

type Props = {
    selectedCategory: string[];
    selectedFilter: string;
    categories: Category[];
    searchCategory?: string[];
    setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
    setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
    setSearchCategory?: React.Dispatch<React.SetStateAction<string[]>>;
    setOpenCart: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCartButton: React.Dispatch<React.SetStateAction<boolean>>;
    setNumberOfCartItems: React.Dispatch<React.SetStateAction<number>>;
    openCart: boolean;
    setCartItems: React.Dispatch<React.SetStateAction<WebInventoryItem[]>>;
    cartItems: WebInventoryItem[];
    addItemToCart: (clickedItem: WebInventoryItem, updateQty: boolean) => void;
    removeFromCart: (citemno: string, nqty?: number, bAll?: boolean) => void;
    runCheckout?: boolean;
    currentAddress?: ScheduleAddress;
    setCurrentAddress?: React.Dispatch<React.SetStateAction<ScheduleAddress>>;
    refreshCartItems: boolean;
};

const MainStore: React.FC<Props> = ({
    selectedCategory,
    setSelectedCategory,
    selectedFilter,
    setSelectedFilter,
    categories,
    setCategories,
    searchCategory,
    setSearchCategory,
    setOpenCart,
    setShowCartButton,
    setNumberOfCartItems,
    openCart,
    setCartItems,
    cartItems,
    addItemToCart,
    removeFromCart,
    runCheckout,
    currentAddress,
    setCurrentAddress,
    refreshCartItems,
}) => {
    //const [selectedCategory, setSelectedCategory] = React.useState("ALL");
    //const [selectedFilter, setSelectedFilter] = React.useState("0");
    //const [categories, setCategories] = React.useState([] as Category[]);
    const [sideBarOpen, setSideBarOpen] = React.useState(false);
    //const [category, setCategory] = React.useState("ALL");
    //const [filter, setFilter] = React.useState("0");
    const [sideBarWidth, setSideBarWidth] = React.useState(300);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [totalItems, setTotalItems] = React.useState(0);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [applyFilters, setApplyFilters] = React.useState(false);
    const [showItem, setShowItem] = React.useState(false);
    const [view, setView] = React.useState("products");

    const isMobile = useIsMobile();
    //const [cartOpen, setCartOpen] = React.useState(false);

    setShowCartButton(true);

    const handleCategoryChange = (event: React.SyntheticEvent, nodeId: string[]) => {
        //setSelectedCategory(nodeId);
        console.log("MainStore handleCategoryChange", nodeId);
    };
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFilter((event.target as HTMLInputElement).value);
        console.log("MainStore handleFilterChange", (event.target as HTMLInputElement).value);
    };

    React.useEffect(() => {
        setNumberOfCartItems(totalItems);
    }, [totalItems]);

    React.useEffect(() => {
        if (filterOpen) setApplyFilters(false);
        else setApplyFilters(true);
    }, [filterOpen]);

    const handleSearchCategoryChange = (searchCategory: string[]) => {
        if (searchCategory) setSearchCategory && setSearchCategory(searchCategory);
    };

    const getStoreMargin = () => {
        return sideBarOpen ? sideBarWidth : 6;
    };

    interface FilterDialogProps {
        open: boolean;
        /*selectedValue: string;
    onClose: (value: string) => void;*/
    }

    function FilterDialog(props: FilterDialogProps) {
        const { open } = props;

        /*const handleClose = () => {
      onClose(selectedValue);
    };*/

        return (
            <Dialog
                open={open}
                onClose={() => setApplyFilters(true)}
            >
                <StoreSideBar
                    onCategoryChange={handleCategoryChange}
                    onFilterChange={handleFilterChange}
                    categories={categories}
                    searchCategory={searchCategory}
                    setSideBarOpen={setFilterOpen}
                    category={selectedCategory}
                    filter={selectedFilter}
                    setSelectedCategory={setSelectedCategory}
                    setSelectedFilter={setSelectedFilter}
                    isDialog={true}
                />
            </Dialog>
        );
    }

    return (
        <>
            <FilterDialog open={filterOpen} />

            <Box sx={{ width: "100%", typography: "body1" }}>
                <NavBreadcrumbs currentView={view} />

                <table
                    className='store_table'
                    style={{ width: "100%", height: "100%" }}
                >
                    <tbody>
                        <tr style={{ height: "100%", verticalAlign: "top" }}>
                            <Hidden lgDown>
                                <td
                                    vertical-align='top'
                                    style={{
                                        width: sideBarWidth,
                                        height: "100%",
                                        verticalAlign: "top",
                                    }}
                                >
                                    {/*{!showItem && (*/}
                                    <div
                                        style={{
                                            width: sideBarWidth,
                                            height: "Calc(100% - 291px)",
                                            overflow: "100%",
                                        }}
                                    >
                                        <StoreSideBar
                                            onCategoryChange={handleCategoryChange}
                                            onFilterChange={handleFilterChange}
                                            categories={categories}
                                            searchCategory={searchCategory}
                                            setSideBarOpen={setFilterOpen}
                                            category={selectedCategory}
                                            filter={selectedFilter}
                                            setSelectedCategory={setSelectedCategory}
                                            setSelectedFilter={setSelectedFilter}
                                            isDialog={false}
                                        />
                                    </div>
                                </td>
                            </Hidden>
                            {/*})}*/}
                            <td style={{ width: "100%" }}>
                                <Box className='store_box'>
                                    <StoreItems
                                        onCategoryChange={handleCategoryChange}
                                        onFilterChange={handleFilterChange}
                                        selectedCategory={selectedCategory}
                                        selectedFilter={selectedFilter}
                                        categories={categories}
                                        setCategories={setCategories}
                                        setNavBarSearchCategory={handleSearchCategoryChange}
                                        setTotalItems={setTotalItems}
                                        setCartOpen={setOpenCart}
                                        setSideBarOpen={setFilterOpen}
                                        cartOpen={openCart}
                                        setSelectedCategory={setSelectedCategory}
                                        setSelectedFilter={setSelectedFilter}
                                        applyFilters={applyFilters}
                                        setCartItems={setCartItems}
                                        addItemToCart={addItemToCart}
                                        cartItems={cartItems || []}
                                        removeFromCart={removeFromCart}
                                        runCheckout={runCheckout}
                                        currentAddress={currentAddress}
                                        setCurrentAddress={setCurrentAddress}
                                        setShowItem={setShowItem}
                                        showItem={showItem}
                                        refreshCartItems={refreshCartItems}
                                    />
                                </Box>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
        </>
    );
};

export default MainStore;
