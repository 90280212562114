// React
import React, { ReactElement, ReactNode, useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// Components
import AdminDashboard from "./admin/AdminDashboard";
import Checkout from "./components/Checkout";
import ModifyCreditCards from "./components/ModifyCreditCards";
import { Category, ScheduleAddress, WebInventoryItem } from "./components/StoreItems";
// Helpers
import NavigationProvider from "./helpers/NavigationProvider";
// Pages
import Cart from "./pages/Cart";
import DashboardPage from "./pages/DashboardPage";
import FAQs from "./pages/FAQs";
import MainStore from "./pages/MainStore";
import MyAccount from "./pages/MyAccount";
import MyAdmin from "./pages/MyAdmin";
import PayInvoices from "./pages/PayInvoices";
import Payments from "./pages/Payments";
import Reports from "./pages/Reports";
import ServiceRequests from "./pages/ServiceRequests";
import Test from "./pages/Test";
import ViewOpenInvoices from "./pages/ViewOpenInvoices";
import MyProfile from "./pages/MyProfile";
// Services
import {
    GetSettings,
    GetTenant,
    GetTenantName,
    GetUserName,
    Settings,
    getSecrets2,
} from "./services/GetInvoiceData";
// UI Components
import TenantsCreateForm from "ui-components/TenantsCreateForm";
// Layouts
import MainLayout from "./layouts/MainLayout";
import AccountMenu from "./layouts/AccountLayout";
import { HubProvider } from "./context/HubContext";
import ErrorDisplay from "pages/ErrorDisplay";
import { JsxElement } from "typescript";
import LoadingSpinner from "components/LoadingSpinner";
import CustomBox from "theme/components/Box";

interface Props {
    signout?: any;
    user?: any;
}

type pages = {
    key: string;
    value: ReactElement;
    default: boolean;
};

const App = (props: Props) => {
    const [selectedCategory, setSelectedCategory] = React.useState(["ALL"]);
    const [selectedFilter, setSelectedFilter] = React.useState("0");
    const [categories, setCategories] = React.useState([] as Category[]);
    const [searchCategory, setSearchCategory] = React.useState([""]);
    const [category, setCategory] = React.useState("ALL");
    const [filter, setFilter] = React.useState("0");
    const [showCartButton, setShowCartButton] = React.useState(false);
    const [numberOfCartItems, setNumberOfCartItems] = React.useState(0);
    const [openCart, setOpenCart] = React.useState(false);
    const [tenant, setTenant] = React.useState({} as any);
    const [cartItems, setCartItems] = React.useState([] as WebInventoryItem[]);
    const [currentAddress, setCurrentAddress] = React.useState({} as ScheduleAddress);
    const [refreshCartItems, setRefreshCartItems] = React.useState(false);
    const [currentView, setCurrentView] = React.useState("makePayments");
    const [dashboardPage, setDashboardPage] = React.useState({} as ReactNode);
    const [mainStorePage, setMainStorePage] = React.useState({} as ReactNode);
    const [payInvoicesPage, setPayInvoicesPage] = React.useState({} as ReactNode);
    const [permissionPages, setPermissionPages] = React.useState([] as pages[]);
    const [defaultPage, setDefaultPage] = React.useState({} as ReactNode);
    const [settings, setSettings] = React.useState([] as Settings[]);
    const [loadPastCartItems, setLoadPastCartItems] = React.useState(true);

    const addItemToCart = (clickedItem: WebInventoryItem, updateQty: boolean = false) => {
        setCartItems(addItemToCartHelper(clickedItem, updateQty));
    };

    const addItemToCartHelper = (clickedItem: WebInventoryItem, updateQty: boolean = false) => {
        const prev = cartItems;
        const isItemInCart = prev.find((item) => item.citemno === clickedItem.citemno);

        if (clickedItem.nqty === 0) clickedItem.nqty = 1;

        if (isItemInCart) {
            return prev.map((item) =>
                item.citemno === clickedItem.citemno ?
                    {
                        ...item,
                        nqty:
                            updateQty ?
                                clickedItem.nqty
                            :   item.nqty + clickedItem.nqty > 999 ? 999 : item.nqty + clickedItem.nqty,
                    }
                :   item,
            );
        }
        return [...prev, { ...clickedItem }];
    };

    const removeFromCart = (citemno: string, nqty?: number, bAll?: boolean) => {
        setCartItems(removeFromCartHelper(citemno, nqty, bAll));
    };

    React.useEffect(() => {
        const key = GetUserName() + "_" + GetTenantName() + "_Cart";
        console.log("App: Restoring Cart.", key);
        const pastCart = localStorage.getItem(key);
        if (pastCart) {
            console.log("App: Restoring Past Cart.  ", pastCart);
            setCartItems(JSON.parse(pastCart));
            setLoadPastCartItems(false);
        }
    }, [refreshCartItems]);

    const getTotalItems = () => {
        if(!cartItems) return 0;

        return cartItems.reduce((acc, item) => acc + item.nqty, 0);
    }

    React.useEffect(() => {
        setNumberOfCartItems(getTotalItems());
        if(!loadPastCartItems)
            localStorage.setItem(
                GetUserName() + "_" + GetTenantName() + "_Cart",
                JSON.stringify(cartItems),
            );
    }, [cartItems]);

    const removeFromCartHelper = (
        citemno: string,
        nqty?: number,
        bAll?: boolean,
    ): WebInventoryItem[] => {
        console.log("removeFromCart: ", citemno);
        let returnCart = cartItems;
        //let returnCart = prev;

        if (nqty === undefined) nqty = 1;
        if (bAll === undefined) bAll = false;

        returnCart.reduce((acc, item) => {
            if (item.citemno === citemno) {
                if (bAll || item.nqty === nqty) {
                    returnCart = acc;
                    return returnCart;
                }
                console.log("removeFromCart item.amount: ", item.nqty);
                returnCart = [...acc, { ...item, nqty: item.nqty - (nqty || 1) }];
                return returnCart;
            } else {
                returnCart = [...acc, item];
                return returnCart;
            }
        }, [] as WebInventoryItem[]);

        return returnCart;
    };

    React.useEffect(() => {
        async function fetchData() {
            const allSettings = await GetSettings();
            console.log("353: allSettings: ", allSettings);
            if (allSettings) {
                setSettings(allSettings);
            }

            const tenantObj = await GetTenant();

            if (tenantObj) {
                setTenant(tenantObj);
            }
        }
        console.log("Getting configuration data....");
        getSecrets2();

        fetchData();
    }, []);

    React.useEffect(() => {
        async function getPages() {
            if (settings && settings.length > 0) {
                const defPage = await getSettingByName("defaultpage");
                if (!defPage) return;
                console.log("353: Default Page: ", defPage);
                console.log("353: Settings: ", settings);
                setDefaultPage(defPage);
            } else {
                console.log("353: No settings found");
            }
        }
        getPages();
    }, [settings]);

    /* async function getDefaultPage(page: string) {
        const defp = await getSettingByName("defaultpage");

        if (defp.trim().toLowerCase() === page.trim().toLowerCase()) {
            return <div>Could not find page {page}.</div>;
        }

        return getPageMainLayout(defp);
    }*/
    async function getSettingByName(name: string) {
        var value = "";

        if (!settings || settings.length === 0) return value;

        try {
            value =
                settings.find((x: any) => x.name.trim().toLowerCase() === name.trim().toLowerCase())
                    ?.value || "";
        } catch (err) {
            console.log("Error getting setting by name " + name + ".", err);
        }
        return value.trim().toLowerCase();
    }

    /*    const pagesToCheckPermissions = ["viewopeninvoices", "dashboardpage", "mainstore"];

    async function getPermissionPages() {
        if (!settingsState || settingsState.length === 0) return;

        const defp = await getSettingByName("defaultpage");

        var p = [] as pages[];

        try {
            for (let i = 0; i < pagesToCheckPermissions.length; i++) {
                const x = pagesToCheckPermissions[i];
                const value = await getPageMainLayout(x);
                p.push({ key: x, value: value, default: defp === x });
            }
            setPermissionPages(p);
        } catch (err) {
            console.log("Error getting permission pages.", err);
        }
    }

    function getPage(page: string) {
        const retPage = permissionPages.find((x) => x.key === page);
        if (!retPage) {
            return <div>Could not find page {page}.</div>;
        }
    }

    async function getPageMainLayout(page: string) {
        const defp = page ? page.trim().toLowerCase() : "";
        var retPage = await getDefaultPage(page);

        var setting = await getSettingByName("show" + defp);
        console.log("5settings", setting);
        if (setting !== "1") {
            return (
                <MainLayout>
                    <ErrorDisplay
                        message='Page does not exist or you do not have permission to view this page.'
                        onGoBack={() => {
                            window.location.href = "/";
                        }}
                    ></ErrorDisplay>
                </MainLayout>
            );
        } else {
            if (defp === "viewopeninvoices") retPage = <ViewOpenInvoices />;
            else if (defp === "dashboardpage") retPage = <DashboardPage />;
            else if (defp === "mainstore")
                retPage = (
                    <MainStore
                        selectedCategory={selectedCategory}
                        selectedFilter={selectedFilter}
                        categories={categories}
                        setSelectedCategory={setSelectedCategory}
                        setSelectedFilter={setSelectedFilter}
                        setCategories={setCategories}
                        searchCategory={searchCategory}
                        setSearchCategory={setSearchCategory}
                        setShowCartButton={setShowCartButton}
                        setNumberOfCartItems={setNumberOfCartItems}
                        setOpenCart={setOpenCart}
                        openCart={openCart}
                        addItemToCart={addItemToCart}
                        cartItems={cartItems || []}
                        setCartItems={setCartItems}
                        removeFromCart={removeFromCart}
                        currentAddress={currentAddress}
                        setCurrentAddress={setCurrentAddress}
                        refreshCartItems={refreshCartItems}
                    />
                );
        }
        return (
            <MainLayout
                signOut={props.signout}
                showStoreBar={1 === 1}
                selectedCategory={selectedCategory}
                selectedFilter={selectedFilter}
                categories={categories}
                setSelectedCategory={setSelectedCategory}
                setSelectedFilter={setSelectedFilter}
                setCategories={setCategories}
                searchCategory={searchCategory}
                setSearchCategory={setSearchCategory}
                showCartButton={showCartButton}
                numberOfCartItems={numberOfCartItems}
                setOpenCart={setOpenCart}
                setNumberOfCartItems={setNumberOfCartItems}
                setShowCartButton={setShowCartButton}
                openCart={openCart}
            >
                {retPage}
            </MainLayout>
        );
    }*/

    return (
        <>
            <HubProvider>
                <CustomBox>
                    <BrowserRouter>
                        <NavigationProvider>
                            <Routes>
                                <Route
                                    path='/'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            showStoreBar={1 === 1}
                                            selectedCategory={selectedCategory}
                                            selectedFilter={selectedFilter}
                                            categories={categories}
                                            setSelectedCategory={setSelectedCategory}
                                            setSelectedFilter={setSelectedFilter}
                                            setCategories={setCategories}
                                            searchCategory={searchCategory}
                                            setSearchCategory={setSearchCategory}
                                            showCartButton={showCartButton}
                                            numberOfCartItems={numberOfCartItems}
                                            setOpenCart={setOpenCart}
                                            setNumberOfCartItems={setNumberOfCartItems}
                                            setShowCartButton={setShowCartButton}
                                            openCart={openCart}
                                        >
                                            {defaultPage === "mainstore" ?
                                                <MainStore
                                                    selectedCategory={selectedCategory}
                                                    selectedFilter={selectedFilter}
                                                    categories={categories}
                                                    setSelectedCategory={setSelectedCategory}
                                                    setSelectedFilter={setSelectedFilter}
                                                    setCategories={setCategories}
                                                    searchCategory={searchCategory}
                                                    setSearchCategory={setSearchCategory}
                                                    setShowCartButton={setShowCartButton}
                                                    setNumberOfCartItems={setNumberOfCartItems}
                                                    setOpenCart={setOpenCart}
                                                    openCart={openCart}
                                                    addItemToCart={addItemToCart}
                                                    cartItems={cartItems || []}
                                                    setCartItems={setCartItems}
                                                    removeFromCart={removeFromCart}
                                                    currentAddress={currentAddress}
                                                    setCurrentAddress={setCurrentAddress}
                                                    refreshCartItems={refreshCartItems}
                                                />
                                            : defaultPage === "dashboardpage" ?
                                                <DashboardPage />
                                            : defaultPage === "viewopeninvoices" ?
                                                <Payments />
                                            :   null}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/dashboardpage'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showdashboardpage".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <DashboardPage />
                                            :   <div>Could not find page.</div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/viewinvoices'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showviewopeninvoices".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <ViewOpenInvoices />
                                            :   <div>Could not find page.</div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/payinvoices'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <PayInvoices />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/reports'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <Reports />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/servicerequests'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showservicerequests",
                                                )?.value || "0") === "1"
                                            ) ?
                                                <ServiceRequests
                                                    address={currentAddress}
                                                    setAddress={setCurrentAddress}
                                                />
                                            :   <div>Could not find page.</div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/products'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            showStoreBar={1 === 1}
                                            selectedCategory={selectedCategory}
                                            selectedFilter={selectedFilter}
                                            categories={categories}
                                            setSelectedCategory={setSelectedCategory}
                                            setSelectedFilter={setSelectedFilter}
                                            setCategories={setCategories}
                                            searchCategory={searchCategory}
                                            setSearchCategory={setSearchCategory}
                                            showCartButton={showCartButton}
                                            numberOfCartItems={numberOfCartItems}
                                            setOpenCart={setOpenCart}
                                            setNumberOfCartItems={setNumberOfCartItems}
                                            setShowCartButton={setShowCartButton}
                                            openCart={openCart}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showmainstore".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <MainStore
                                                    selectedCategory={selectedCategory}
                                                    selectedFilter={selectedFilter}
                                                    categories={categories}
                                                    setSelectedCategory={setSelectedCategory}
                                                    setSelectedFilter={setSelectedFilter}
                                                    setCategories={setCategories}
                                                    searchCategory={searchCategory}
                                                    setSearchCategory={setSearchCategory}
                                                    setShowCartButton={setShowCartButton}
                                                    setNumberOfCartItems={setNumberOfCartItems}
                                                    setOpenCart={setOpenCart}
                                                    openCart={openCart}
                                                    addItemToCart={addItemToCart}
                                                    cartItems={cartItems || []}
                                                    setCartItems={setCartItems}
                                                    removeFromCart={removeFromCart}
                                                    currentAddress={currentAddress}
                                                    setCurrentAddress={setCurrentAddress}
                                                    refreshCartItems={refreshCartItems}
                                                />
                                            :   <div>Could not find page.</div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/cart/checkout'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            showStoreBar={1 === 1}
                                            selectedCategory={selectedCategory}
                                            selectedFilter={selectedFilter}
                                            categories={categories}
                                            setSelectedCategory={setSelectedCategory}
                                            setSelectedFilter={setSelectedFilter}
                                            setCategories={setCategories}
                                            searchCategory={searchCategory}
                                            setSearchCategory={setSearchCategory}
                                            showCartButton={showCartButton}
                                            numberOfCartItems={numberOfCartItems}
                                            setOpenCart={setOpenCart}
                                            setNumberOfCartItems={setNumberOfCartItems}
                                            setShowCartButton={setShowCartButton}
                                            openCart={openCart}
                                        >
                                            <Checkout
                                                address={currentAddress}
                                                cartItems={cartItems || []}
                                                setCartItems={setCartItems}
                                            />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/cart'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <Cart
                                                setOpenCart={setOpenCart}
                                                address={currentAddress}
                                                addToCart={addItemToCart}
                                                cartItems={cartItems || []}
                                                removeFromCart={removeFromCart}
                                                checkout={() => {}}
                                                setAddress={setCurrentAddress}
                                                refreshCartItems={refreshCartItems}
                                                setRefreshCartItems={setRefreshCartItems}
                                            />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/admin'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <AdminDashboard />
                                        </MainLayout>
                                    }
                                />{" "}
                                <Route
                                    path='/addtenant'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <TenantsCreateForm />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/faqs'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <FAQs />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/myaccount'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                            activeButton='/myprofile'
                                        >
                                            <MyProfile />
                                        </AccountMenu>
                                    }
                                />
                                <Route
                                    path='/paymethods'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                            activeButton='/paymethods'
                                        >
                                            <ModifyCreditCards ccustno='NEEDTOSELECTCUSTOMER' />
                                        </AccountMenu>
                                    }
                                />
                                <Route
                                    path='/payments'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showviewopeninvoices".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <Payments />
                                            :   <div>Could not find page.</div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/myadmin'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <MyAdmin />
                                        </AccountMenu>
                                    }
                                />
                                <Route
                                    path='/myprofile'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <MyProfile />
                                        </AccountMenu>
                                    }
                                />
                                {/*<Route
                                path='/reports'
                                element={
                                    <AccountMenu
                                        signOut={props.signout}
                                        numberOfCartItems={numberOfCartItems}
                                    >
                                        <Reports />
                                    </AccountMenu>
                                }
                            />*/}
                                {/*
                            <Route
                                path='/tickets'
                                element={
                                    <MainLayout
                                        signOut={props.signout}
                                        numberOfCartItems={numberOfCartItems}
                                    >
                                        <Tickets />
                                    </MainLayout>
                                }
                            />
                            */}
                                {/*
                            <Route
                                path='/printinvoices'
                                element={
                                    <MainLayout
                                        signOut={props.signout}
                                        numberOfCartItems={numberOfCartItems}
                                    >
                                        <PrintInvoices />
                                    </MainLayout>
                                }
                            />
                            */}
                                {/*
                            <Route
                                path='/printtickets'
                                element={
                                    <MainLayout
                                        signOut={props.signout}
                                        numberOfCartItems={numberOfCartItems}
                                    >
                                        <PrintTickets />
                                    </MainLayout>
                                }
                            />
                            */}
                                <Route
                                    path='/test'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <Test />
                                        </MainLayout>
                                    }
                                />
                            </Routes>
                        </NavigationProvider>
                    </BrowserRouter>
                </CustomBox>
            </HubProvider>
        </>
    );
};

export default App;
