import React from "react";
import AWS from "aws-sdk";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { HttpRequest } from "@smithy/protocol-http";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Hash } from "@smithy/hash-node";
import { ChangeEvent, ChangeEventHandler, SetStateAction, useState } from "react";
import { createPresignedPost } from "@aws-sdk/s3-presigned-post";
import FormDataLib from "form-data";
import { Box, Dialog } from "@mui/material";
import { SettingsPowerRounded } from "@mui/icons-material";

const S3_BUCKET = "prism-web-images";
const REGION = "us-east-1";

AWS.config.update({
    accessKeyId: "AKIARAQAMTKFXJBS5CX5",
    secretAccessKey: "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",
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

type Props = {
    setURL: (url: string) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    tenantId: string;
    directory?: string;
};

const UploadFile: React.FC<Props> = ({ setURL, open, setOpen, tenantId, directory }) => {
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null as any);
    const [isOpen, setIsOpen] = useState(false);

    const handleFileInput = (e: any) => {
        setSelectedFile(e.target.files[0]);
    };

    function generateUUID() {
        // Public Domain/MIT
        var d = new Date().getTime(); //Timestamp
        var d2 =
            (typeof performance !== "undefined" && performance.now && performance.now() * 1000) ||
            0; //Time in microseconds since page-load or 0 if unsupported
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if (d > 0) {
                //Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                //Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
    }

    const uploadFile = (file: any, setURL: (url: string) => void) => {
        const fileExtension = file.name.split(".").pop();
        const fileName = (directory ? directory + "/" : "") + (tenantId === "" ? "" : (tenantId + "/")) + generateUUID() + "." + fileExtension;

        const params = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: fileName,
        };

        console.log("params: ", params);

        /*myBucket
            .putObject(params)
            .on("httpUploadProgress", (evt: any) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err: any, data: any) => {
                if (err) console.log(err);
                else console.log("Success", data);
            }).promise().then(() => {
                
            });
*/

        myBucket
            .upload(params)
            .on("httpUploadProgress", (evt: any) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .promise()
            .then((data: any) => {
                console.log(data.Location);
                setURL(data.Location);
                alert("Image uploaded successfully");
            })
            .catch((err: any) => console.log(err));
        setOpen(false);
    };

    return (
        <Dialog open={open}>
            <Box m='30px'>
                <div>Upload Progress: {progress}%</div>
                <input
                    type='file'
                    onChange={handleFileInput}
                />
                <br />
                <br />
                <button onClick={() => uploadFile(selectedFile, setURL)}> Upload Image</button>
            </Box>
        </Dialog>
    );
};

export default UploadFile;
