// React
import * as React from "react";
// Material UI
import { Button } from "@aws-amplify/ui-react";
import HomeIcon from "@mui/icons-material/Home";
import { IconButton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { experimentalStyled as styled } from "@mui/material/styles";
import { getUserGroups } from "../services/GetInvoiceData";
import TenantsCreateForm from "../ui-components/TenantsCreateForm";
import { getOverrideProps } from "../ui-components/utils";
import ClientsCreateForm from "../ui-components/ClientsCreateForm";
// Admin
import UpdateClients from "./UpdateClients";
import UpdateSettings from "./UpdateSettings";
import UpdateTenants from "./UpdateTenants";
import UpdateUsers from "./UpdateUsers";
import UpdateHubs from "./UpdateHubs";
import UpdateSpecialImages from "./UpdateSpecialImages";
// Amplify
import UsersCreateForm from "../ui-components/UsersCreateForm";
import HubsCreateForm from "ui-components/HubsCreateForm";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const pages = [
    {
        name: "Create New Client",
        link: "CreateClients",
    },
    {
        name: "Update Clients",
        link: "UpdateClients",
    },
    {
        name: "Create New Tenant",
        link: "CreateTenants",
    },
    {
        name: "Update Tenants",
        link: "UpdateTenants",
    },
    {
        name: "Create New Hub",
        link: "CreateHubs",
    },
    {
        name: "Update Hub",
        link: "UpdateHubs",
    },
    {
        name: "Create User",
        link: "CreateUsers",
    },
    {
        name: "Update Users",
        link: "UpdateUsers",
    },
    {
        name: "Update Settings",
        link: "UpdateSettings",
    },
    ,
    {
        name: "Update Specials Images",
        link: "UpdateSpecialImages",
    },
];

export default function AdminDashboard() {
    const [selectedPage, setSelectedPage] = React.useState("");
    const [isAdmin, setIsAdmin] = React.useState(false);

    React.useEffect(() => {
        getUserGroups().then((groups) => {
            if (!groups) return;
            if (groups.includes("Prism_admin")) setIsAdmin(true);
        });
    }, []);

    function showPage() {
        if (!isAdmin) {
            return (
                <div>
                    <h1>Unauthorized</h1>
                </div>
            );
        }

        switch (selectedPage) {
            case "UpdateSettings":
                return (
                    <div>
                        <h1>Settings</h1>
                        <UpdateSettings
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                            onCancel={() => {
                                setSelectedPage("");
                            }}
                        />
                    </div>
                );
            case "CreateClients":
                return (
                    <div>
                        <h1>Create Client</h1>
                        <ClientsCreateForm
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                            onCancel={() => {
                                setSelectedPage("");
                            }}
                        />
                    </div>
                );
            case "UpdateClients":
                return (
                    <div>
                        <h1>Update Clients</h1>
                        <UpdateClients
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                        />
                    </div>
                );
            case "CreateTenants":
                return (
                    <div>
                        <h1>Create Tenant</h1>
                        <TenantsCreateForm
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                            onCancel={() => {
                                setSelectedPage("");
                            }}
                        />
                    </div>
                );
            case "UpdateTenants":
                return (
                    <div>
                        <h1>Update Tenants</h1>
                        <UpdateTenants
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                        />
                    </div>
                );
            case "CreateHubs":
                return (
                    <div>
                        <h1>Create Hub</h1>
                        <HubsCreateForm
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                            onCancel={() => {
                                setSelectedPage("");
                            }}
                        />
                    </div>
                );
            case "UpdateHubs":
                return (
                    <div>
                        <h1>Update Hubs</h1>

                        <UpdateHubs
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                        />
                    </div>
                );
            case "CreateUsers":
                return (
                    <div>
                        <h1>Create User</h1>
                        <UsersCreateForm
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                            onCancel={() => {
                                setSelectedPage("");
                            }}
                        ></UsersCreateForm>
                    </div>
                );
            case "UpdateUsers":
                return (
                    <div>
                        <h1>Update Users</h1>
                        <UpdateUsers
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                        ></UpdateUsers>
                    </div>
                );
            case "UpdateSpecialImages":
                return (
                    <div>
                        <h1>Update Specials Images</h1>
                        <UpdateSpecialImages
                            onSuccess={() => {
                                setSelectedPage("");
                            }}
                            onCancel={() => {
                                setSelectedPage("");
                            }}
                        ></UpdateSpecialImages>
                    </div>
                );
            case "AddNewTenantOld":
                return <TenantsCreateForm />;
            default:
                return (
                    <>
                        <Stack
                            direction={"column"}
                            sx={{
                                width: "100%",
                                alignItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                            }}
                        >
                            <h1>Admin Dashboard</h1>
                            <br />
                            <br />
                            <Grid
                                container
                                maxWidth='400px'
                                width={"100%"}
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {pages.map((page, index) => (
                                    <Grid
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        key={index}
                                    >
                                        <Button
                                            isFullWidth={true}
                                            children={page && page.name}
                                            type='reset'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if(page)
                                                    setSelectedPage(page.link);
                                            }}
                                            {...getOverrideProps(null, "ClearButton")}
                                        ></Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </>
                );
        }
    }

    return (
        <Box sx={{ flexGrow: 1, alignItems: "center" }}>
            <Box sx={{ width: "100vw" }} />
            <IconButton
                onClick={() => {
                    setSelectedPage("");
                }}
                color='primary'
                aria-label='go home'
            >
                <HomeIcon />
            </IconButton>
            <br />
            <br />
            <br />
            {showPage()}
        </Box>
    );
}
