/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createClients = /* GraphQL */ `mutation CreateClients(
  $input: CreateClientsInput!
  $condition: ModelClientsConditionInput
) {
  createClients(input: $input, condition: $condition) {
    companycode
    name
    tenants {
      items {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientsMutationVariables,
  APITypes.CreateClientsMutation
>;
export const updateClients = /* GraphQL */ `mutation UpdateClients(
  $input: UpdateClientsInput!
  $condition: ModelClientsConditionInput
) {
  updateClients(input: $input, condition: $condition) {
    companycode
    name
    tenants {
      items {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientsMutationVariables,
  APITypes.UpdateClientsMutation
>;
export const deleteClients = /* GraphQL */ `mutation DeleteClients(
  $input: DeleteClientsInput!
  $condition: ModelClientsConditionInput
) {
  deleteClients(input: $input, condition: $condition) {
    companycode
    name
    tenants {
      items {
        id
        subdomain
        usesHubs
        name
        url
        uniqueid
        createdby
        logo
        loginsideimage
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        clientsTenantsCompanycode
        tenantsLinkedclientCompanycode
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientsMutationVariables,
  APITypes.DeleteClientsMutation
>;
export const createTenants = /* GraphQL */ `mutation CreateTenants(
  $input: CreateTenantsInput!
  $condition: ModelTenantsConditionInput
) {
  createTenants(input: $input, condition: $condition) {
    id
    subdomain
    usesHubs
    name
    url
    uniqueid
    createdby
    logo
    loginsideimage
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    hubs {
      items {
        id
        chubno
        tenantid
        description
        logo
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        tenantsHubsId
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    linkedclient {
      companycode
      name
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    clientsTenantsCompanycode
    tenantsLinkedclientCompanycode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantsMutationVariables,
  APITypes.CreateTenantsMutation
>;
export const updateTenants = /* GraphQL */ `mutation UpdateTenants(
  $input: UpdateTenantsInput!
  $condition: ModelTenantsConditionInput
) {
  updateTenants(input: $input, condition: $condition) {
    id
    subdomain
    usesHubs
    name
    url
    uniqueid
    createdby
    logo
    loginsideimage
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    hubs {
      items {
        id
        chubno
        tenantid
        description
        logo
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        tenantsHubsId
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    linkedclient {
      companycode
      name
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    clientsTenantsCompanycode
    tenantsLinkedclientCompanycode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTenantsMutationVariables,
  APITypes.UpdateTenantsMutation
>;
export const deleteTenants = /* GraphQL */ `mutation DeleteTenants(
  $input: DeleteTenantsInput!
  $condition: ModelTenantsConditionInput
) {
  deleteTenants(input: $input, condition: $condition) {
    id
    subdomain
    usesHubs
    name
    url
    uniqueid
    createdby
    logo
    loginsideimage
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    hubs {
      items {
        id
        chubno
        tenantid
        description
        logo
        companyname
        address1
        address2
        city
        state
        zip
        phone
        email
        createdAt
        updatedAt
        tenantsHubsId
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    linkedclient {
      companycode
      name
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    clientsTenantsCompanycode
    tenantsLinkedclientCompanycode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTenantsMutationVariables,
  APITypes.DeleteTenantsMutation
>;
export const createUsers = /* GraphQL */ `mutation CreateUsers(
  $input: CreateUsersInput!
  $condition: ModelUsersConditionInput
) {
  createUsers(input: $input, condition: $condition) {
    id
    username
    firstname
    lastname
    tenants {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUsersMutationVariables,
  APITypes.CreateUsersMutation
>;
export const updateUsers = /* GraphQL */ `mutation UpdateUsers(
  $input: UpdateUsersInput!
  $condition: ModelUsersConditionInput
) {
  updateUsers(input: $input, condition: $condition) {
    id
    username
    firstname
    lastname
    tenants {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUsersMutationVariables,
  APITypes.UpdateUsersMutation
>;
export const deleteUsers = /* GraphQL */ `mutation DeleteUsers(
  $input: DeleteUsersInput!
  $condition: ModelUsersConditionInput
) {
  deleteUsers(input: $input, condition: $condition) {
    id
    username
    firstname
    lastname
    tenants {
      items {
        id
        tenantsId
        usersId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUsersMutationVariables,
  APITypes.DeleteUsersMutation
>;
export const createHubs = /* GraphQL */ `mutation CreateHubs(
  $input: CreateHubsInput!
  $condition: ModelHubsConditionInput
) {
  createHubs(input: $input, condition: $condition) {
    id
    chubno
    tenantid
    description
    logo
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    tenant {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    createdAt
    updatedAt
    tenantsHubsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHubsMutationVariables,
  APITypes.CreateHubsMutation
>;
export const updateHubs = /* GraphQL */ `mutation UpdateHubs(
  $input: UpdateHubsInput!
  $condition: ModelHubsConditionInput
) {
  updateHubs(input: $input, condition: $condition) {
    id
    chubno
    tenantid
    description
    logo
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    tenant {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    createdAt
    updatedAt
    tenantsHubsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHubsMutationVariables,
  APITypes.UpdateHubsMutation
>;
export const deleteHubs = /* GraphQL */ `mutation DeleteHubs(
  $input: DeleteHubsInput!
  $condition: ModelHubsConditionInput
) {
  deleteHubs(input: $input, condition: $condition) {
    id
    chubno
    tenantid
    description
    logo
    companyname
    address1
    address2
    city
    state
    zip
    phone
    email
    tenant {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    createdAt
    updatedAt
    tenantsHubsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHubsMutationVariables,
  APITypes.DeleteHubsMutation
>;
export const createTenantsUsers = /* GraphQL */ `mutation CreateTenantsUsers(
  $input: CreateTenantsUsersInput!
  $condition: ModelTenantsUsersConditionInput
) {
  createTenantsUsers(input: $input, condition: $condition) {
    id
    tenantsId
    usersId
    tenants {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    users {
      id
      username
      firstname
      lastname
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantsUsersMutationVariables,
  APITypes.CreateTenantsUsersMutation
>;
export const updateTenantsUsers = /* GraphQL */ `mutation UpdateTenantsUsers(
  $input: UpdateTenantsUsersInput!
  $condition: ModelTenantsUsersConditionInput
) {
  updateTenantsUsers(input: $input, condition: $condition) {
    id
    tenantsId
    usersId
    tenants {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    users {
      id
      username
      firstname
      lastname
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTenantsUsersMutationVariables,
  APITypes.UpdateTenantsUsersMutation
>;
export const deleteTenantsUsers = /* GraphQL */ `mutation DeleteTenantsUsers(
  $input: DeleteTenantsUsersInput!
  $condition: ModelTenantsUsersConditionInput
) {
  deleteTenantsUsers(input: $input, condition: $condition) {
    id
    tenantsId
    usersId
    tenants {
      id
      subdomain
      usesHubs
      name
      url
      uniqueid
      createdby
      logo
      loginsideimage
      companyname
      address1
      address2
      city
      state
      zip
      phone
      email
      hubs {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      linkedclient {
        companycode
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      clientsTenantsCompanycode
      tenantsLinkedclientCompanycode
      __typename
    }
    users {
      id
      username
      firstname
      lastname
      tenants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTenantsUsersMutationVariables,
  APITypes.DeleteTenantsUsersMutation
>;
